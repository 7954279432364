import React, { useContext, useEffect, useState } from "react"
import { AppStateContext } from "@/components/contexts/AppContext"
import { Button } from "@/components/common"
import { Checkbox, message } from "antd"
import AWSSettings from "./hosting/AWSSettings"
import SFtpSettings from "./hosting/SFTPSettings"
import GithubHostingSettings from "./hosting/GithubHostingSettings"
import AzureSettings from "./hosting/AzureSettings"
import AWSIcon from "@/resources/images/aws_s3.svg"
import AzureIcon from "@/resources/images/azure.svg"
import ProjectClient from "@/clients/ProjectClient"

const HostingSettings: React.ComponentType = (props) => {
  const projectClient = new ProjectClient();
  const { state } = useContext(AppStateContext)
  const [hosting, setHosting] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (state.project!.data.hosting) {
      setHosting(state.project!.data.hosting)
    }
  }, [])

  const updateHosting = (val: string) => {
    setHosting(val)
  }

  return (
    <div {...props}>
      {hosting !== "aws" && (
        <div className="translation-single-item">
          <div className="translation-single-item-name-wrapper">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src={AWSIcon}
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">AWS S3</div>
          </div>
          <button
            className="button-black w-button"
            onClick={() => updateHosting("aws")}
          >
            Connect AWS S3
          </button>
        </div>
      )}
      {hosting === "aws" && <AWSSettings />}
      {hosting !== "azure" && (
        <div className="translation-single-item">
          <div className="translation-single-item-name-wrapper">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src={AzureIcon}
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">Azure</div>
          </div>
          <button
            className="button-black w-button"
            onClick={() => updateHosting("azure")}
          >
            Connect Azure Storage
          </button>
        </div>
      )}
      {hosting === "azure" && <AzureSettings />}
      {hosting !== "sftp" && (
        <div className="translation-single-item">
          <div className="translation-single-item-name-wrapper">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src={require("@/resources/images/sftp.png")}
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">SFTP</div>
          </div>
          <button
            className="button-black w-button"
            onClick={() => updateHosting("sftp")}
          >
            Configure SFTP
          </button>
        </div>
      )}
      {hosting === "sftp" && <SFtpSettings />}

      {/* <div className="project-overview-card-right" style={{ position: "relative" }}>
        <div className="project-overview-name-text">Hosting Settings</div>
      </div>
      <div className="project-overview-card-right" style={{ position: "relative" }}>
        <div className="project-overview-name-text">
          <Checkbox onClick={() => updateHosting("aws")} checked={hosting === "aws"}>AWS</Checkbox>
          {state.project!.github_url && <Checkbox onClick={() => updateHosting("github")} checked={hosting === "github"}>Github</Checkbox>}
          <Checkbox onClick={() => updateHosting("azure")} checked={hosting === "azure"}>Azure</Checkbox>
        </div>
      </div>
      <div className="hosting-form">

        <AWSSettings />
        <div className="translation-single-item">
          <div className="translation-single-item-name-wrapper">
            <div className="icon-wrapper"><img loading="lazy" src="https://assets.website-files.com/625e72e3b276d01282f085dd/62cfd69e9b9e417d01aa0ee0_image%2067%20(3).svg" alt="" className="object-fit-cover" /></div>
            <div className="translation-single-item-text">Heroku</div>
          </div><a href="#" className="button-black w-button">Connect to Heroku</a>
        </div>
      </div> */}
    </div>
  )
}

export default HostingSettings
