import { Project, ProjectAsset } from "@/models/Project"
import React, { useEffect, useState, useContext } from "react"
import ProjectAssetClient from "@/clients/ProjectAssetClient"
import { Collapse, Skeleton, Switch } from "antd"
import { AppStateContext } from "../contexts/AppContext"

interface RepositoryCodeTabProps {
  supportingFiles: Array<ProjectAsset>
  asset: ProjectAsset
  project: Project
}

const RepositoryCodeTab: React.ComponentType<RepositoryCodeTabProps> = (
  props: RepositoryCodeTabProps
) => {
  const assetClient = new ProjectAssetClient()
  const { state } = useContext(AppStateContext)
  const { asset, project, supportingFiles } = props
  const [content, setContent] = useState<any>()
  const [scripts, setScripts] = useState<Array<any>>([])
  const [loadingScript, setLoadingScript] = useState(false)
  const [codeManagement, setCodeManagement] = useState<any>({})

  useEffect(() => {
    // setScripts([])
    setLoadingScript(true)
    assetClient.getScripts(asset.id!).then(
      (res) => {
        // console.log(res)
        // res.forEach((script: any) => {
        //   scripts?.push({
        //     key: script.id,
        //     label: script.source.length > 0 ? script.source : "script",
        //     content: script.content,
        //   })
        // })
        setScripts([...res])
        setLoadingScript(false)
      },
      (error: string) => {
        setLoadingScript(false)
      }
    )

    let codeManagement = state.project!.data.code_management
    if (codeManagement) {
      setCodeManagement(codeManagement)
    } else {
      let codeManagement = {
        code_management_enabled: false,
        allow_webflow_script: true,
        log_webflow_script_changes: false,
        log_script_changes: false,
        email_script_alert: false,
      }
      setCodeManagement(codeManagement)
    }
  }, [])

  useEffect(() => {
    assetClient.getAsssetContent(project.id!, asset.id!).then((cont: any) => {
      supportingFiles.forEach((file: ProjectAsset) => {
        let sourceRegex = new RegExp(file.url_source, "g")
        let pathRegex = new RegExp(file.path!, "g")
        cont.replace(
          sourceRegex,
          `<span class="isurl green">${file.url_source}</span>`
        )
        cont.replace(pathRegex, `<span class="isurl green">${file.path}</span>`)
      })
      setContent(cont)
    })
  }, [asset])

  const getDestination = () => {
    return `/${project.alias}${asset.path}`
  }

  const getScriptContent = (key: any) => {
    if (key) {
      key = parseInt(key)
      let script = scripts.find((s) => {
        return s.id === key
      })

      if (script && script.source.length > 0) {
        assetClient.getScriptContent(key).then(
          (res: any) => {
            script.content = res.content
            setScripts([...scripts])
          },
          (error: string) => {
            console.log(error)
          }
        )
      }
    }
  }

  const setScriptEnabled = (script: any, enabled: boolean) => {
    script.enabled = enabled
    setScripts([...scripts])
    assetClient.updateScript(script.id, { enabled })
  }

  return (
    <div {...props}>
      <div>
        <div className="project-overview-name-text">Inspect</div>
        <div>
          <p>
            You are inspecting the asset as it appears in the current build.
            URLs may differ from the original source, as they may have been
            replaced with links to other assets of this build that are stored
            locally.
          </p>
          <b>File Hosted Locally At:</b>
          <p>{getDestination()}</p>
        </div>
        <div className="project-overview-name-text" style={{ marginTop: 15 }}>
          Page Scripts
        </div>
        <div className="content">
          <Skeleton loading={loadingScript} active>
            <Collapse accordion onChange={getScriptContent}>
              {scripts?.map((script, index) => {
                if (script.count.usage > 0)
                  return (
                    <Collapse.Panel
                      header={
                        <div className="script-header">
                          <span className="title">
                            {script.source ? script.source : "script"}
                          </span>
                          <span className="global">
                            ({`${script.count.usage}/${script.count.total}`})
                          </span>
                        </div>
                      }
                      key={script.id}
                    >
                      {codeManagement.code_management_enabled && (
                        <div className="settings-single-item-wrapper two-column">
                          <div className="setting-single-item-text-wrapper">
                            <div className="settings-grid-item-text">
                              Enabled?
                            </div>
                          </div>
                          <div className="settings-form-block w-form">
                            <Switch
                              checked={script.enabled}
                              onChange={(checked) => {
                                setScriptEnabled(script, checked)
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="small code-block code">
                        <pre
                          style={{
                            wordWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {script.content}
                        </pre>
                      </div>
                    </Collapse.Panel>
                  )
              })}
            </Collapse>
          </Skeleton>
        </div>
        <div className="project-overview-name-text" style={{ marginTop: 15 }}>
          Page URLs
        </div>
        <div className="content">
          <span className="isurl2 bgorange">
            {process.env.REACT_APP_SITE_URL + "/projects/" + project.alias}
          </span>
          <br />
          <span className="isurl2 blue">/files</span>
          <br />
          <span className="isurl2 blue">/images</span>
        </div>
        <div className="content">
          {supportingFiles.map((file: ProjectAsset) => {
            if (file.type!.includes("file")) {
              return (
                <p key={file.id}>
                  <span className="isurl2 green">{file.path}</span>
                  <br />
                  <span className="isurl2 red">{file.url_source}</span>
                </p>
              )
            }
          })}
        </div>
        <div className="small code-block code">
          <pre dangerouslySetInnerHTML={{ __html: content }}></pre>
        </div>
      </div>
    </div>
  )
}

export default RepositoryCodeTab
