import React, { useState, useEffect, useContext } from "react"
import { AppStateContext } from "../contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { ProjectAssetVersion, Script } from "@/models/Project"
import moment from "moment"
import { Skeleton, Collapse, Switch } from "antd"

const WebflowScriptVersions = () => {
  const [codeManagement, setCodeManagement] = useState<any>({})
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const [versions, setVersions] = useState<Array<ProjectAssetVersion>>([])
  const [loading, setLoading] = useState(false)
  const assetUrl = process.env.REACT_APP_SITE_URL
  const [scripts, setScripts] = useState<Array<Script>>([])

  useEffect(() => {
    setLoading(true)
    projectClient.getWebflowScriptVersions(state.project!.id!).then(
      (res: Array<ProjectAssetVersion>) => {
        setVersions(res)
        setLoading(false)
      },
      (error: string) => {
        console.log(error)
        setLoading(false)
      }
    )

    projectClient.getProjectScripts(state.project!.id!).then(
      (res: Array<Script>) => {
        setScripts(res)
      },
      (error: string) => {
        console.log(error)
      }
    )

    if (state.project && state.project.data) {
      let codeManagement = state.project!.data.code_management
      if (codeManagement) {
        setCodeManagement(codeManagement)
      } else {
        let codeManagement = {
          code_management_enabled: false,
          allow_webflow_script: true,
          log_webflow_script_changes: false,
          log_script_changes: false,
          email_script_alert: false,
        }
        setCodeManagement(codeManagement)
      }
    }
  }, [])

  const getScriptVersions = (scriptId: any) => {
    projectClient.getScriptVersions(state.project!.id!, scriptId).then(
      (res: Array<ProjectAssetVersion>) => {
        scripts.map((scr: Script) => {
          if (scr.id === parseInt(scriptId)) {
            scr.versions = res
          }
        })

        setScripts([...scripts])
      },
      (error: string) => {
        console.log(error)
      }
    )
  }

  return (
    <div style={{ width: "100%" }}>
      {codeManagement.code_management_enabled &&
        codeManagement.allow_webflow_script &&
        codeManagement.log_webflow_script_changes && (
          <div>
            <div className="dashboard-overview-text">Webflow Script Logs</div>
            <Skeleton loading={loading}>
              {versions.map((version: any, index: number) => {
                return (
                  <div
                    key={version.id}
                    className={
                      "repository-single-item-wrapper" +
                      (index % 2 === 0 ? " is-grey" : "")
                    }
                  >
                    <div className="repository-name-wrapper-w-padding">
                      <div className="page-grid-item-text">
                        <span className="version-created">
                          {moment(version.created_at!).fromNow()}
                        </span>
                      </div>
                    </div>
                    <div className="repository-name-wrapper-w-padding">
                      <div className="page-grid-item-text">
                        {(version.size / 1024).toFixed(2)}KB
                      </div>
                    </div>
                    <div className="repository-name-wrapper-w-padding">
                      <div className="page-grid-item-text">
                        <a
                          className="view-version"
                          href={`${assetUrl}${version.content_url}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Skeleton>
          </div>
        )}

      {codeManagement.code_management_enabled &&
        codeManagement.log_script_changes && (
          <div>
            <div className="dashboard-overview-text">Custom Script Logs</div>
            <Collapse accordion onChange={getScriptVersions}>
              {scripts?.map((script, index) => {
                if (script.count.usage > 0)
                  return (
                    <Collapse.Panel
                      header={
                        <div className="script-header">
                          <span className="title">
                            {script.source ? script.source : "script"}
                          </span>
                          <span className="global">
                            ({`${script.count.usage}/${script.count.total}`})
                          </span>
                        </div>
                      }
                      key={script.id!}
                    >
                      <div className="">
                        {script.versions &&
                          script.versions!.map(
                            (version: any, index: number) => {
                              return (
                                <div
                                  key={version.id}
                                  className={
                                    "repository-single-item-wrapper" +
                                    (index % 2 === 0 ? " is-grey" : "")
                                  }
                                >
                                  <div className="repository-name-wrapper-w-padding">
                                    <div className="page-grid-item-text">
                                      <span className="version-created">
                                        {moment(version.created_at!).fromNow()}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="repository-name-wrapper-w-padding">
                                    <div className="page-grid-item-text">
                                      {(version.size / 1024).toFixed(2)}KB
                                    </div>
                                  </div>
                                  <div className="repository-name-wrapper-w-padding">
                                    <div className="page-grid-item-text">
                                      <a
                                        className="view-version"
                                        href={`${assetUrl}${version.content_url}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        View
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                          )}
                      </div>
                    </Collapse.Panel>
                  )
              })}
            </Collapse>
          </div>
        )}
    </div>
  )
}

export default WebflowScriptVersions
