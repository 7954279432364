import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import Button from "../common/Button"
import { AppStateContext } from "../contexts/AppContext"

const CodeManagement = () => {
  const [codeManagement, setCodeManagement] = useState<any>({})
  const { state } = useContext(AppStateContext)

  useEffect(() => {
    if (state.project && state.project.data) {
      let codeManagement = state.project!.data.code_management
      if (codeManagement) {
        setCodeManagement(codeManagement)
      } else {
        let codeManagement = {
          code_management_enabled: false,
          allow_webflow_script: true,
          log_webflow_script_changes: false,
          log_script_changes: false,
          email_script_alert: false,
        }
        setCodeManagement(codeManagement)
      }
    }
  }, [])

  return (
    <React.Fragment>
      {codeManagement.code_management_enabled && (
        <div className="code-management">
          <div>
            <div className="dashboard-overview-text">Code Watchlist</div>
          </div>
          <div className="extensions-card-wrapper">
            <div className="dashboard-card-left-text-wrapper">
              <div className="text-light">
                Webflow Code:{" "}
                {codeManagement.allow_webflow_script
                  ? "Approved"
                  : "Disapproved"}
              </div>
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="text-light">
                Log Webflow Code Changes:{" "}
                {codeManagement.log_webflow_script_changes
                  ? "Enabled"
                  : "Disabled"}
              </div>
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="text-light">
                Log Script Changes:{" "}
                {codeManagement.log_script_changes ? "Enabled" : "Disabled"}
              </div>
            </div>
            <div className="dashboard-card-left-text-wrapper">
              <div className="text-light">
                Email Scipt Changes:{" "}
                {codeManagement.email_script_alert ? "Enabled" : "Disabled"}
              </div>
            </div>
          </div>
          <Link to={`/project/${state.project!.alias}/settings`}>
            <Button
              label="Edit"
              className="button-black w-button"
              style={{ minWidth: "inherit" }}
            />
          </Link>
        </div>
      )}
    </React.Fragment>
  )
}

export default CodeManagement
