import React, { useState, useContext, useEffect } from "react"
import { Switch, message } from "antd"
import { Button } from "@/components/common"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { Project } from "@/models/Project"
import { Actions } from "../reducers/AppReducer"

const ScriptSettings: React.ComponentType = (props) => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [codeManagementEnabled, setCodeManagementEnabled] = useState(false)
  const [allowWebflowScript, setAllowWebflowScript] = useState(true)
  const [logWebflowScriptChanges, setLogWebflowScriptChanges] = useState(false)
  const [logScriptChanges, setLogScriptChanges] = useState(false)
  const [emailScriptAlert, setEmailScriptAlert] = useState(false)
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(state.project && state.project.data) {
      let codeManagement = state.project!.data.code_management
      if(codeManagement) {
        setCodeManagementEnabled(codeManagement.code_management_enabled);
        setAllowWebflowScript(codeManagement.allow_webflow_script);
        setLogWebflowScriptChanges(codeManagement.log_webflow_script_changes);
        setLogScriptChanges(codeManagement.log_script_changes);
        setEmailScriptAlert(codeManagement.email_script_alert)
        setEmail(codeManagement.email_to)
      }
    }
  }, []);

  const updateProject = () => {
    setLoading(true)
    let data = {
      code_management: {
        code_management_enabled: codeManagementEnabled,
        allow_webflow_script: allowWebflowScript,
        log_webflow_script_changes: logWebflowScriptChanges,
        log_script_changes: logScriptChanges,
        email_script_alert: emailScriptAlert,
        email_to: email,
      },
    }

    state.project!.data = data
    projectClient.updateProject(state.project?.id!, state.project!).then(
      (proj: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: state.project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
      }
    )
  }

  return (
    <div {...props}>
      <div className="padding-vertical-big">
        <div className="settings-section">
          <div className="settings-left">
            <div className="settings-heading-wrapper">
              <h2 className="settings-heading normal">Code Management</h2>
            </div>
            <div className="settings-single-item-wrapper two-column-script">
              <div className="setting-single-item-text-wrapper">
                <div className="settings-grid-item-text">
                  Enabled Global Code Management
                </div>
              </div>
              <div className="settings-form-block w-form">
                <Switch
                  checked={codeManagementEnabled}
                  onChange={(checked) => setCodeManagementEnabled(checked)}
                />
              </div>
            </div>
            {codeManagementEnabled && (
              <div className="script-settings">
                <div className="settings-category">
                  <div className="settings-grid-item-text">
                    <h6>Javascript - Webflow Default</h6>
                  </div>
                  <div className="settings-single-item-wrapper two-column-script">
                    <div className="setting-single-item-text-wrapper">
                      <div className="settings-grid-item-text">
                        Allow Webflow Default Javascript
                      </div>
                    </div>
                    <div className="settings-form-block w-form">
                      <Switch
                        checked={allowWebflowScript}
                        onChange={(checked) => setAllowWebflowScript(checked)}
                      />
                    </div>
                  </div>
                  <div className="settings-single-item-wrapper two-column-script">
                    <div className="setting-single-item-text-wrapper">
                      <div className="settings-grid-item-text">
                        Log Webflow Default Javacript Changes
                      </div>
                    </div>
                    <div className="settings-form-block w-form">
                      <Switch
                        checked={logWebflowScriptChanges}
                        onChange={(checked) =>
                          setLogWebflowScriptChanges(checked)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="settings-category">
                  <div className="settings-grid-item-text">
                    <h6>Custom Code</h6>
                  </div>
                  <div className="settings-single-item-wrapper two-column-script">
                    <div className="setting-single-item-text-wrapper">
                      <div className="settings-grid-item-text">
                        Log Javascript Changes
                      </div>
                    </div>
                    <div className="settings-form-block w-form">
                      <Switch
                        checked={logScriptChanges}
                        onChange={(checked) => setLogScriptChanges(checked)}
                      />
                    </div>
                  </div>
                  <div className="settings-single-item-wrapper two-column-script">
                    <div className="setting-single-item-text-wrapper">
                      <div className="settings-grid-item-text">
                        Email Javascript Alerts
                      </div>
                    </div>
                    <div className="settings-form-block w-form">
                      <Switch
                        checked={emailScriptAlert}
                        onChange={(checked) => setEmailScriptAlert(checked)}
                      />
                    </div>
                  </div>
                  {emailScriptAlert && (
                    <div className="settings-single-item-wrapper two-column-script">
                      <div className="setting-single-item-text-wrapper">
                        <div className="settings-single-item">
                          Send Email To:
                        </div>
                      </div>
                      <div className="settings-form-block w-form">
                        <input
                          type="text"
                          className="settings-input-field w-input"
                          name="Alias"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          data-name="Alias"
                          placeholder=""
                          id="Alias"
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="settings-single-item-wrapper two-column-script">
              <div className="setting-single-item-text-wrapper">
                <div className="settings-grid-item-text"></div>
              </div>
              <div className="settings-form-block w-form">
                <Button
                  label="Save Changes"
                  loading={loading}
                  className="button-black w-button"
                  onClick={updateProject}
                  style={{ minWidth: "inherit" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScriptSettings
