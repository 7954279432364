import React, { useContext, useEffect, useState } from "react"
import { Actions } from "@/components/reducers/AppReducer"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import ProjectClient from "@/clients/ProjectClient"
import { Project, ProjectAwsSetting } from "@/models/Project"
import { Button } from "@/components/common"
import { message, Upload } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import BaseClient from "@/clients/BaseClient"
import JWTHelper from "@/utilities/JWTHelper"

const AWSSettings: React.ComponentType = () => {
  const projectClient = new ProjectClient()
  const { state } = useContext(AppStateContext)
  const { dispatch } = useContext(AppDispatchContext)
  const [host, setHost] = useState("")
  const [port, setPort] = useState(22)
  const [username, setUsername] = useState("")
  const [passphrase, setPassphrase] = useState("")
  const [folder, setFolder] = useState("")
  const [hasPublicKey, setHasPublicKey] = useState(false)
  const [hasPrivateKey, setHasPrivateKey] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (state.project!.data.sftp_data) {
      setHost(state.project!.data.sftp_data.host)
      setPort(state.project!.data.sftp_data.port)
      setUsername(state.project!.data.sftp_data.username)
      setPassphrase(state.project!.data.sftp_data.passphrase)
      setFolder(state.project!.data.sftp_data.folder)
      setHasPublicKey(state.project!.data.sftp_data.has_public_key)
      setHasPrivateKey(state.project!.data.sftp_data.has_private_key)
    }
  }, [])

  const setupSFTP = () => {
    if (host.length === 0) {
      message.error("Please enter host.")
      return
    }

    if (port <= 0) {
      message.error("Please enter port.")
      return
    }

    if (username.length === 0) {
      message.error("Please enter username.")
      return
    }

    state.project!.data.hosting = "sftp"
    state.project!.data.sftp_data = {
      host: host,
      port: port,
      username: username,
      passphrase: passphrase,
      folder: folder,
      has_public_key: hasPublicKey,
      has_private_key: hasPrivateKey,
    }

    setLoading(true)
    projectClient.updateProject(state.project!.id!, state.project!).then(
      (project: Project) => {
        dispatch({ type: Actions.UPDATE_PROJECT, payload: project })
        setLoading(false)
      },
      (error: string) => {
        message.error(error)
        setLoading(false)
      }
    )
  }

  const uploadProps = {
    name: "file",
    action: `${projectClient.baseUrl}/project/${state.project!
      .id!}/upload/ssh/keys`, // Replace with your upload endpoint
    headers: {
      Authorization: `Bearer ${JWTHelper.getAccessToken()}`,
    },
    onChange(info: any) {
      // console.log(info)
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`)
        var sftpData = info.file.response.data.sftp_data
        if (sftpData.has_public_key) setHasPublicKey(sftpData.has_public_key)
        if (sftpData.has_private_key) setHasPrivateKey(sftpData.has_private_key)
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`)
      }
    },
  }

  return (
    <div>
      <div className="translation-single-item-opened">
        <div className="translation-single-item-left">
          <div className="translation-single-item-name-wrapper-opened">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src={require("@/resources/images/sftp.png")}
                alt=""
                className="object-fit-cover"
              />
            </div>
            <div className="translation-single-item-text">SFTP Client</div>
          </div>
        </div>
        <div className="translation-single-item-right">
          <div className="w-form">
            <div className="other-single-item-wrapper">
              <div
                id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c2-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Host</div>
              </div>
              <div
                id="w-node-cfc9a258-12ca-a1d2-d884-e1b1f9f3c7c5-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="host"
                  value={host}
                  onChange={(e) => setHost(e.target.value)}
                  data-name="Key"
                  placeholder="Enter Host"
                  id="host"
                  required
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_605ab198-92f3-0d15-32db-d3d8d317ff55-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Port</div>
              </div>
              <div
                id="w-node-_605ab198-92f3-0d15-32db-d3d8d317ff58-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="number"
                  className="no-margin w-input"
                  name="port"
                  value={port}
                  onChange={(e) => setPort(parseInt(e.target.value))}
                  data-name="Region"
                  placeholder="Enter port"
                  id="port"
                />
              </div>
            </div>

            <div className="other-single-item-wrapper">
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f1-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Username</div>
              </div>
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f4-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  data-name="username"
                  placeholder="Enter username"
                  id="username"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_17239fc9-e44b-99a7-ae7c-c115a455709a-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Passphrase</div>
              </div>
              <div
                id="w-node-_17239fc9-e44b-99a7-ae7c-c115a455709d-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="password"
                  className="no-margin w-input"
                  name="passphrase"
                  value={passphrase}
                  onChange={(e) => setPassphrase(e.target.value)}
                  data-name="passphrase"
                  placeholder="Enter Passphrase"
                  id="passphrase"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f1-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Folder</div>
              </div>
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f4-907a86d7"
                className="repository-name-wrapper"
              >
                <input
                  type="text"
                  className="no-margin w-input"
                  name="folder"
                  value={folder}
                  onChange={(e) => setFolder(e.target.value)}
                  data-name="folder"
                  placeholder="Enter folder"
                  id="folder"
                />
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f1-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Public key</div>
              </div>
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f4-907a86d7"
                className="repository-name-wrapper"
              >
                <Upload {...uploadProps} data={{ file_type: "public" }}>
                  <Button
                    label="Click to Upload"
                    icon={<UploadOutlined />}
                    className="button-black align-right w-button"
                  ></Button>
                </Upload>
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f1-907a86d7"
                className="repository-name-wrapper"
              >
                <div>Private key</div>
              </div>
              <div
                id="w-node-_703d799b-5926-448b-313a-141e813165f4-907a86d7"
                className="repository-name-wrapper"
              >
                <Upload {...uploadProps} data={{ file_type: "private" }}>
                  <Button
                    label="Click to Upload"
                    icon={<UploadOutlined />}
                    className="button-black align-right w-button"
                  ></Button>
                </Upload>
              </div>
            </div>
            <div className="other-single-item-wrapper">
              <div></div>
              <div className="flex">
                <Button
                  label="Update"
                  loading={loading}
                  className="button-black align-right w-button"
                  onClick={setupSFTP}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AWSSettings
