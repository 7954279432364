import UserClient from "@/clients/UserClient"
import { User, UserToken } from "@/models/User"
import JWTHelper from "@/utilities/JWTHelper"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "@/components/common"
import { message } from "antd"
import {
  AppStateContext,
  AppDispatchContext,
} from "@/components/contexts/AppContext"
import { Actions } from "../reducers/AppReducer"
import AccessRequestClient from "@/clients/AccessRequestClient"
import { Team, TeamMember } from "@/models/Project"
import TeamClient from "@/clients/TeamClient"

const Login: React.ComponentType = () => {
  let navigate = useNavigate()
  let userClient = new UserClient()
  const teamClient = new TeamClient()
  let requestClient = new AccessRequestClient()
  const { dispatch } = useContext(AppDispatchContext)

  const [showFail, setShowFail] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [requestEmail, setRequestEmail] = useState("")
  const [requestFirstName, setRequestFirstName] = useState("")
  const [requestLastName, setRequestLastName] = useState("")
  const [requestPhoneNumber, setRequestPhoneNumber] = useState("")
  const [signinParam, setSigninParam] = useState<boolean>(false);



  const signin = () => {
    
    if (window.location.search.includes("signin")) {
      setSigninParam(true)
    }
  
    // if (signinParam) {
    //   const tab2Element = document.querySelector(`[data-w-tab="Tab 2"]`) as HTMLElement;
    //   if (tab2Element) {
    //     tab2Element!.click();
    //     setSigninParam(false)
    //   }
    // }
  }

  useEffect(() => {
    if (JWTHelper.isLogin()) navigate("/home", { replace: true })

    signin && signin()
  })

  const login = (e: React.MouseEvent) => {
    e.preventDefault()
    setLoading(true)
    userClient
      .login(username, password)
      .then(
        (token: UserToken) => {
          JWTHelper.storeToken(token)
          userClient.getCurrentUser().then(
            (user: User) => {
              teamClient.getTeams().then((team: TeamMember) => {
                if (team) {
                  dispatch({ type: Actions.UPDATE_TEAM, payload: team })
                }
              })
              dispatch({ type: Actions.UPDATE_USER, payload: user })
              navigate("/home", { replace: true })
            },
            (error) => {
              setShowFail(true)
              setLoading(false)
              // message.error(error);
            }
          )
        },
        (error: string) => {
          setShowFail(true)
          setLoading(false)
          // message.error(error);
        }
      )
      .finally(() => setLoading(false))
  }

  const sendRequest = () => {
    if (requestEmail.length === 0) {
      message.error("Please enter your email.")
      return
    }

    let data = {
      first_name: requestFirstName,
      last_name: requestLastName,
      email: requestEmail,
      phone_number: requestPhoneNumber,
      topic: "",
      signinParam: false
    }

    setLoading(true)
    requestClient.sendEmail(data).then(
      () => {
        setLoading(false)
        message.success("Your request has been sent.")
      },
      (error: string) => {
        setLoading(false)
        message.error(error)
      }
    )
  }

  const webflowAuthUrl = (): string => {
    return `https://webflow.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_WEBFLOW_CLIENT_ID}&state=login&scope=custom_code%3Aread%20custom_code%3Awrite%20sites%3Aread%20pages%3Aread%20authorized_user%3Aread`
  }

  return (
    <div className="page-wrapper page-marketing">
      <div className="main-wrapper">
        <div className="login-gradient">
          <section className="login-section">
            <div className="padding-global z-index-2">
              <div className="container-large">
                <div className="login_component text-color-white">
                  <div className="login_text-wrapper">
                    <div className="margin-bottom margin-xxlarge">
                      <a href="/">
                        <img
                          src="/images/65c4e7432e88796b1b8e7023_Wes.svg"
                          loading="lazy"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="margin-bottom margin-medium">
                      <h1 className="heading-style-h1-large smaller-line-height">
                        Your dev link between Webflow &amp; everything else.
                      </h1>
                    </div>
                    <div className="margin-bottom margin-xxlarge">
                      <p className="text-size-medium">
                        Wes allows you to build in Webflow and control
                        deployment with the tech stack of your choice.
                      </p>
                    </div>
                    {/* <div className="login_quote-box">
                      <div className="quote-spacing">
                        <p className="text-size-regular-plus">
                          “ Lorem ipsum dolor sit amet consectetur. Turpis urna
                          volutpat risus vel id. Ac sit eget in gravida at
                          molestie morbi. Cursus fusce lacus est risus ant.”
                        </p>
                      </div>
                      <div className="margin-top margin-small">
                        <h5 className="text-size-small text-weight-normal">
                          John Smith
                        </h5>
                      </div>
                    </div> */}
                  </div>
                  <div className="login_form-wrapper">
                    <div
                      data-current="Tab 1"
                      data-easing="ease"
                      data-duration-in="300"
                      data-duration-out="100"
                      className="login_tabs w-tabs"
                    >
                      <div className="login_selector-group w-tab-menu">
                        <a
                          data-w-tab="Tab 1"
                          onClick={() => setSigninParam(false)}
                          className={"login_button w-inline-block w-tab-link " + (signinParam ? "" : "w--current")}
                          ><div>Book a Demo</div></a>
                          <a
                          data-w-tab="Tab 2"
                          onClick={() => setSigninParam(true)}
                          className={"login_button w-inline-block w-tab-link " + (signinParam ? "w--current" : "")}
                          ><div>Sign In</div></a>
                      </div>
                      <div className="w-tab-content">
                        <div
                          data-w-tab="Tab 1"
                          className={"w-tab-pane" + (!signinParam ? "w--tab-active" : "")}
                        >
                          <div className="margin-vertical margin-medium">
                            <div className="text-align-center">
                              <h3 className="text-color-black heading-style-h4">
                                See how it works
                              </h3>
                            </div>
                          </div>
                          <div className="login_form-block w-form">
                            <form
                              id="wf-form-Book-Demo"
                              name="wf-form-Book-Demo"
                              data-name="Book Demo"
                              method="get"
                              className="login_form"
                              data-wf-page-id="65c4e026c9d10398295973e1"
                              data-wf-element-id="1a5cfe66-e2d1-64ec-9594-877af3582dd0"
                            >
                              <div className="login_field-group">
                                <div>
                                  <label className="login_lable">First name</label>
                                  <input
                                    className="login_field w-input"
                                    name="name"
                                    data-name="Name"
                                    value={requestFirstName}
                                    onChange={(e) => setRequestFirstName(e.target.value)}
                                    placeholder=""
                                    type="text"
                                    id="name"
                                  />
                                </div>
                                <div>
                                  <label className="login_lable">Last name</label>
                                  <input
                                    className="login_field w-input"
                                    name="Last"
                                    value={requestLastName}
                                    onChange={(e) => setRequestLastName(e.target.value)}
                                    data-name="Last"
                                    placeholder=""
                                    type="text"
                                    id="Last"
                                  />
                                </div>
                              </div>
                              <div>
                                <label className="login_lable">Email Address</label>
                                <input
                                  type="email"
                                  value={requestEmail}
                                  onChange={(e) => setRequestEmail(e.target.value)}
                                  className="login_field w-input"
                                  name="Email"
                                  data-name="Email"
                                  placeholder=""
                                  id="Email-2"
                                />
                              </div>
                              <div>
                                <label className="login_lable">Phone number (optional)</label>
                                <input
                                  className="login_field w-input"
                                  name="Phone"
                                  data-name="Phone"
                                  value={requestPhoneNumber}
                                  onChange={(e) => setRequestPhoneNumber(e.target.value)}
                                  placeholder=""
                                  type="email"
                                  id="Phone"
                                />
                              </div>
                              <div className="login_submit-bttn-wrapper">
                                 <Button
                                  label="Join the waitlist"
                                  onClick={sendRequest}
                                  loading={loading}
                                  className="login_submit-bttn w-button"
                                />
                              </div>
                            </form>
                            <div className="w-form-done">
                              <div>
                                Thank you! Your submission has been received!
                              </div>
                            </div>
                            <div className="w-form-fail">
                              <div>
                                Oops! Something went wrong while submitting the
                                form.
                              </div>
                            </div>
                          </div>
                          {/* <p className="text-color-black text-weight-medium">
                            Don&#x27;t have an account? &nbsp;
                            <a href="#" className="login_request-link">Request access.</a>
                          </p> */}
                        </div>
                        <div data-w-tab="Tab 2" className={"w-tab-pane" + (signinParam ? "w--tab-active" : "")}>
                          <div className="margin-vertical margin-medium">
                            <div className="text-align-center">
                              <h3 className="text-color-black heading-style-h4">
                                Welcome
                              </h3>
                              <p className="text-color-black text-weight-medium">
                                Sing in to your account to manage projects.
                              </p>
                            </div>
                          </div>
                          <div className="login_form-block w-form">
                            <form
                              id="wf-form-Login"
                              name="wf-form-Login"
                              data-name="Login"
                              method="get"
                              className="login_form"
                              data-wf-page-id="65c4e026c9d10398295973e1"
                              data-wf-element-id="3df24b59-808e-d58b-f683-9617dddd7cd8"
                            >
                              <div>
                                <label className="login_lable">Email Address</label>
                                <input
                                  type="email"
                                  className="login_field w-input"
                                  value={username}
                                  onChange={(e) => setUsername(e.target.value)}
                                  maxLength={256}
                                  name="Email"
                                  data-name="Email"
                                  placeholder="Email"
                                  id="Email"
                                />
                              </div>
                              <div>
                                <label className="login_lable">Password</label>
                                <input
                                  type="password"
                                  className="login_field w-input"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  maxLength={256}
                                  name="Password"
                                  data-name="Password"
                                  placeholder="Password"
                                  id="Password"
                                  required
                                />
                              </div>
                              <div className="login_submit-bttn-wrapper">
                                <Button
                                  label="Sign in"
                                  onClick={login}
                                  loading={loading}
                                  className="login_submit-bttn w-button"
                                />
                              </div>
                            </form>
                            <div className="w-form-done">
                              <div>
                                Thank you! Your submission has been received!
                              </div>
                            </div>
                            <div className="w-form-fail">
                              <div>
                                Oops! Something went wrong while submitting the
                                form.
                              </div>
                            </div>
                          </div>
                          {/* <p className="text-color-black text-weight-medium">
                            Don&#x27;t have an account?&nbsp;<a href="#" className="login_request-link">Request access.</a>
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="2e6719c8-ca4e-b893-f67b-e47648fc490d"
              className="login_carousel"
            >
              <div className="login_carousel-wrapper">
                <div className="login-carousel-box">
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 21V14"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4 10V3"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 21V12"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V3"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20 21V16"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20 12V3"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 14H7"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 8H15"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17 16H23"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Custom Deployment Flow<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      GDPR Specific Server Deployment<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 2V6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 18V22"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.92969 4.93018L7.75969 7.76018"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.2402 16.2402L19.0702 19.0702"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2 12H6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18 12H22"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.92969 19.0702L7.75969 16.2402"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.2402 7.76018L19.0702 4.93018"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Change Detection<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4V10H7"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.51 14.9999C4.15839 16.8403 5.38734 18.4201 7.01166 19.5013C8.63598 20.5825 10.5677 21.1065 12.5157 20.9944C14.4637 20.8823 16.3226 20.1401 17.8121 18.8797C19.3017 17.6193 20.3413 15.9089 20.7742 14.0063C21.2072 12.1037 21.0101 10.1119 20.2126 8.33105C19.4152 6.55019 18.0605 5.07674 16.3528 4.13271C14.6451 3.18868 12.6769 2.82521 10.7447 3.09707C8.81245 3.36892 7.02091 4.26137 5.64 5.63995L1 9.99995"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Version Control<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 20H21"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Per Page Publishing<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 9.39996L7.5 4.20996"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.26953 6.95996L11.9995 12.01L20.7295 6.95996"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 22.08V12"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Owned CDN<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Locked CSS<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 21V9C6 11.3869 6.94821 13.6761 8.63604 15.364C10.3239 17.0518 12.6131 18 15 18"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Server-Side Code Injections<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16 2V6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 2V6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 10H21"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Managed Release Schedule<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2 12H22"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Translations<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.0004 20.9999L16.6504 16.6499"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Federated Search &amp; Site Map<br />
                    </p>
                  </div>
                </div>
                <div className="login-carousel-box">
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 21V14"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4 10V3"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 21V12"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 8V3"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20 21V16"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20 12V3"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 14H7"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 8H15"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17 16H23"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Custom Deployment Flow<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      GDPR Specific Server Deployment<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 2V6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 18V22"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.92969 4.93018L7.75969 7.76018"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.2402 16.2402L19.0702 19.0702"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2 12H6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18 12H22"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.92969 19.0702L7.75969 16.2402"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.2402 7.76018L19.0702 4.93018"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Change Detection<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4V10H7"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.51 14.9999C4.15839 16.8403 5.38734 18.4201 7.01166 19.5013C8.63598 20.5825 10.5677 21.1065 12.5157 20.9944C14.4637 20.8823 16.3226 20.1401 17.8121 18.8797C19.3017 17.6193 20.3413 15.9089 20.7742 14.0063C21.2072 12.1037 21.0101 10.1119 20.2126 8.33105C19.4152 6.55019 18.0605 5.07674 16.3528 4.13271C14.6451 3.18868 12.6769 2.82521 10.7447 3.09707C8.81245 3.36892 7.02091 4.26137 5.64 5.63995L1 9.99995"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Version Control<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 20H21"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Per Page Publishing<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5 9.39996L7.5 4.20996"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.26953 6.95996L11.9995 12.01L20.7295 6.95996"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 22.08V12"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Owned CDN<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Locked CSS<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 9C7.65685 9 9 7.65685 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.65685 4.34315 9 6 9Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 21V9C6 11.3869 6.94821 13.6761 8.63604 15.364C10.3239 17.0518 12.6131 18 15 18"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Server-Side Code Injections<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M16 2V6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 2V6"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3 10H21"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Managed Release Schedule<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2 12H22"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Translations<br />
                    </p>
                  </div>
                  <div className="login_carousel-item">
                    <div className="login_carousel-icon">
                      <div className="login_icon-flex w-embed">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.0004 20.9999L16.6504 16.6499"
                            stroke="#502DCC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="text-size-regular-plus text-color-white">
                      Federated Search &amp; Site Map<br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-w-id="68ef3f44-f8f7-1218-1340-33ae54260823"
              className="marketing-spline-element"
              data-animation-type="spline"
              data-spline-url="https://prod.spline.design/Lmh8oiTv9cGHCzE4/scene.splinecode"
            >
              <canvas></canvas>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Login
